import React, { Component } from "react"
import Title from "partial/Title"
import FAQLink from "partial/Link"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as actions from "./redux/actions"
import * as actionsBanner from "base/redux/actions"

class Search extends Component {
	componentDidMount() {
		const { match, actions, bannerActions } = this.props
		actions.get_search_article(match.params.query)
		bannerActions.put_banner("change")
	}

	render() {
		const { match, search, translation } = this.props
		return (
			<div id="Search" className="container pt-5 pb-5">
				<Title>
					{translation.txt_search} : {match.params.query}
				</Title>
				<FAQLink data={search.article} search />
			</div>
		)
	}
}

function mapStateToProps(state) {
	return { search: state.search, translation: state.main.translation }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		bannerActions: bindActionCreators(actionsBanner, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
