import { directus, otrs } from "wrapper"
import { getUrlParam } from "helper"
const ls = window.localStorage

export const put_banner = (data) => ({
	type: "PUT_BANNER",
	data,
})

export const toggleAlert = (data = false) => ({
	type: "TOGGLE_ALERT",
	data,
})

export const toggleNotification = (data) => ({
	type: "TOGGLE_NOTIFICATION",
	data,
})

export const toggleTos = () => ({
	type: "TOGGLE_TOS",
})

export const put_alert_content = (title, data) => ({
	type: "PUT_ALERT_CONTENT",
	title,
	data,
})

export const set_token = () => {
	return (dispatch) => {
		let token = getUrlParam("token")
		let redirect_uri = getUrlParam("redirect_uri")
		let redirect_url = `${process.env.REACT_APP_BASE_URL}`

		if (redirect_uri) {
			redirect_uri = decodeURIComponent(redirect_uri)
			redirect_uri = redirect_uri.replace(/^\/|\/$/g, "")
			redirect_url += redirect_uri
		}

		if (token) {
			window.localStorage.token = token
			window.location.href = redirect_url
		} else {
			dispatch(check_login())
		}
	}
}

export const get_translate = () => {
	return (dispatch) => {
		directus
			.get("items/localization?fields=*.*.*")
			.then((resp) => {
				dispatch({
					type: "PUT_TRANSLATE",
					data: resp.translations.find((e) => e.languages_id.code === ls.lang),
				})
			})
			.catch((err) => {
				console.log(err)
			})
	}
}

export const get_game = () => {
	return (dispatch) => {
		directus
			.get("items/games?fields=*.*")
			.then((resp) => {
				dispatch({
					type: "PUT_GAMES",
					data: resp,
				})
			})
			.catch((err) => {
				console.log(err)
			})
	}
}

export const get_category = () => {
	return (dispatch) => {
		directus
			.get("items/articles_types?fields=*.*")
			.then((resp) => {
				dispatch({
					type: "PUT_CATEGORY",
					data: resp,
				})
			})
			.catch((err) => {
				console.log(err)
			})
	}
}

export const check_login = () => {
	return (dispatch) => {
		otrs
			.get("support/check_login/")
			.then((resp) => {
				dispatch({
					type: "PUT_USER",
					data: resp,
				})

				const url = window.localStorage.getItem("last_page")
				if (url) {
					window.localStorage.removeItem("last_page")
					window.location.href = url
				}
			})
			.catch((err) => {
				dispatch({
					type: "PUT_USER",
					data: false,
				})
			})
	}
}

export const map_err = (err) => {
	return (dispatch, getState) => {
		const { translation } = getState().main
		let msg = "Terjadi Kesalahan, Silahkan Refresh Browser Anda",
			status = translation.txt_popup_alert_title_success
		if (err.status !== 500) {
			msg = err.data.msg
		}

		if (err.status > 200) {
			status = translation.txt_popup_alert_title_error
		}
		// alert(msg);
		dispatch(put_alert_content(status, msg))
		dispatch(toggleAlert())
	}
}
