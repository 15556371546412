import React, { Component } from "react"
import Modal from "react-responsive-modal"
import { connect } from "react-redux"

import styles from "./scss/NotificationModal.module.scss"

class NotificationModal extends Component {
	notificationPermission = () => {
		const { closeModal } = this.props

		if (!("Notification" in window)) {
			console.log("This browser does not support desktop notification")
		} else {
			import("firebase/push-notification").then((myModule) => {
				myModule.askForPermissioToReceiveNotifications()
				closeModal()
			})

			// Notification.requestPermission()
			// 	.then((permission) => {
			// 		if (permission === "granted") {
			// 			closeModal()
			// 		}
			// 	})
			// 	.catch((err) => {
			// 		console.log(err)
			// 	})
			// closeModal();
		}
	}

	panduanClick = () => {
		const { translation } = this.props
		window.location.href = translation.txt_complete_guide_link
	}

	render() {
		const { open, closeModal, translation } = this.props

		return (
			<Modal
				open={open}
				onClose={closeModal}
				center
				focusTrapped={false}
				classNames={{
					modal: styles.modal,
					closeButton: styles.closeBtn,
				}}
			>
				<span className={styles.content}>{translation.txt_push_notif_notice}</span>
				<div className={styles.button}>
					<div className={styles.panduan} onClick={() => this.panduanClick()}>
						{translation.txt_complete_guide}
					</div>
					<div className={styles.action}>
						<div onClick={closeModal}>{translation.txt_block}</div>
						<div onClick={() => this.notificationPermission()}>{translation.txt_activate}</div>
					</div>
				</div>
			</Modal>
		)
	}
}

function mapStateToProps(state) {
	return { translation: state.main.translation }
}

export default connect(mapStateToProps)(NotificationModal)
