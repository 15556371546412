import React, { Component } from "react"
import { Link } from "react-router-dom"
import Title from "partial/Title"
import Loader from "partial/Loader"
import LoginModal from "partial/LoginModal"

import styles from "./scss/Post.module.scss"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as actions from "./redux/actions"
import * as actionsBanner from "base/redux/actions"

const ls = window.localStorage

class Post extends Component {
	state = {
		vote: null,
		review: "",
		toggle_login: false,
	}

	componentDidMount() {
		this.getArticle()
	}

	componentWillUnmount() {
		this.props.actions.get_article("clear")
	}

	get_localize = (data) => {
		return data.translations.find?.((e) => e.languages_code.code === ls.lang)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.post !== this.props.post) {
			const { actions, post } = this.props
			actions.get_category_article(post.article[0].game.slug, post.article[0].type.slug)
		}
		if (prevProps.location !== this.props.location) {
			this.getArticle()
		}
	}

	getArticle = () => {
		const { actions, match, bannerActions } = this.props
		actions.get_article(match.params.slug)
		bannerActions.put_banner("change")
	}

	handleChange = (e) => {
		this.setState({
			review: e.currentTarget.value,
		})
	}

	handleSubmit = (e) => {
		e.preventDefault()
		const { post, actions } = this.props
		const { article } = post
		let formdata = new FormData()

		for (const key in this.state) {
			formdata.append(key, this.state[key])
		}

		formdata.append("article_id", article[0].id)
		// formdata.append('game_slug', article.game.slug);
		// formdata.append('faq_type', article.category.type);

		// actions.err();
		actions.vote_article(formdata)
	}

	handleMoreHelp = (e) => {
		e.preventDefault()
		const { user } = this.props

		if (!user) {
			this.handleModalLogin()
		} else {
			window.location.href = process.env.REACT_APP_BASE_URL + "ticket/create"
		}
	}

	handleModalLogin = () => {
		this.setState({
			toggle_login: !this.state.toggle_login,
		})
	}

	populateRate = () => {
		const { user, post, translation } = this.props
		const { is_vote } = post

		if (!user) {
			return
		}

		return (
			<form onSubmit={this.handleSubmit}>
				<div className={styles.formCont}>
					{!is_vote ? (
						<div className={styles.rateCont}>
							<p>{translation.txt_review_question}</p>
							<div className={styles.btnCont}>
								<button
									type="submit"
									className={`${styles.btn} ${this.state.vote === 1 && styles.active}`}
									onClick={() =>
										this.setState({
											vote: 1,
										})
									}
								>
									{translation.txt_yes}
								</button>
								<div
									className={`${styles.btn} ${this.state.vote === 0 && styles.active}`}
									onClick={() =>
										this.setState({
											vote: 0,
										})
									}
								>
									{translation.txt_no}
								</div>
							</div>
							<div className={styles.reviewCont + " " + (this.state.vote === 0 && styles.active)}>
								<p>{translation.txt_review_article_notice}</p>
								<textarea
									name=""
									id=""
									rows="5"
									placeholder={translation.txt_review_article_placeholder}
									onChange={this.handleChange}
								></textarea>
								<button type="submit" className={styles.btn}>
									{translation.txt_send}
								</button>
							</div>
						</div>
					) : (
						<p>{translation.txt_already_review_article}</p>
					)}
				</div>
			</form>
		)
	}

	populateArticle = () => {
		const { article } = this.props.post

		switch (article) {
			case undefined:
				return <Loader />
			case false:
				return (
					<>
						<Title>NOT FOUND</Title>
						<div className={styles.postCont} dangerouslySetInnerHTML={{ __html: "ARTICLE NOT FOUND" }}></div>
					</>
				)
			default:
				return (
					<>
						<Title>{this.get_localize(article[0])?.title}</Title>
						<div
							className={styles.postCont}
							dangerouslySetInnerHTML={{ __html: this.get_localize(article[0])?.content }}
						></div>
					</>
				)
		}
	}

	populateLink = () => {
		const { faq, post } = this.props
		const data = faq[post.article?.[0]?.type.slug]?.filter?.((e) => e.slug !== post.article?.[0]?.slug)

		if (data) {
			return data?.map?.((dt, idx) => (
				<Link key={idx} to={`/${dt.game.slug}/post/${dt.slug}`}>
					<img
						src={
							this.get_localize(dt)?.featured_image?.filename_disk
								? process.env.REACT_APP_CDN_URL + this.get_localize(dt).featured_image.filename_disk
								: "https://via.placeholder.com/1280x720.jpg?text=x"
						}
						alt=""
					/>
					<span>{this.get_localize(dt)?.title}</span>
				</Link>
			))
		} else {
			return <Loader />
		}
	}

	render() {
		const { match, post, translation } = this.props

		return (
			<div className="container">
				<div className="row">
					<div id="Post" className={`pt-5 pb-5 ${styles.post} col`}>
						{this.populateArticle()}
						<div
							className={styles.moreHelp}
							onClick={this.handleMoreHelp}
							dangerouslySetInnerHTML={{
								__html: translation.txt_need_more_help
									?.replace("{link}", "<span>")
									.replace("{link}", "</span>"),
							}}
						></div>
						{this.populateRate()}
						<LoginModal
							open={this.state.toggle_login}
							closeModal={this.handleModalLogin}
							redirectTo={process.env.REACT_APP_BASE_URL + "ticket/create"}
						/>
					</div>
					<div className={`col-sm-3 ${styles.article} pr-sm-0 py-5`}>
						<span className={styles.title}>{translation.txt_related_article}</span>
						{this.populateLink()}
						<Link className={styles.other} to={`/${match.params.games}/list/${post.article?.[0].type.slug}`}>
							{translation.txt_see_more}
						</Link>
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		post: state.post,
		user: state.main.user,
		faq: state.faq,
		translation: state.main.translation,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		bannerActions: bindActionCreators(actionsBanner, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Post)
