import "react-app-polyfill/ie9"
import React from "react"
import ReactDOM from "react-dom"
import ReactGA from "react-ga4"
import axios from "axios"
import * as serviceWorker from "./serviceWorker" // UNCOMMENT FOR PWA

// COMPONENT & OTHER
import "assets/scss/index.scss"
import Main from "base/Main"

// REDUX
import thunk from "redux-thunk"
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import rootReducer from "store"

ReactGA.initialize([
	{
		trackingId: process.env.REACT_APP_GA,
	},
]);
ReactGA.send({
	hitType: "pageview",
	page: window.location.pathname + window.location.search,
});
ReactGA.event({
	category: window.location.origin + window.location.pathname,
	action: window.releases,
});

// SET REDUX STORE
const store = createStore(rootReducer, applyMiddleware(thunk))

// SET DEFAULT AXIOS
// axios.defaults.withCredentials = true;
// axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

if (process.env.REACT_APP_USE_TOKEN === "true") {
	var token = window.localStorage.getItem("token")
	axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = token
}

ReactDOM.render(
	<Provider store={store}>
		<Main />
	</Provider>,
	document.getElementById("root")
)

serviceWorker.unregister() // UNCOMMENT TO REMOVE PWA || COMMENT THE REGISTER
// serviceWorker.register(); // UNCOMMENT FOR PWA
// serviceWorker.registerPushManager();
