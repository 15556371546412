import React, { Component } from "react"
import { Link } from "react-router-dom"
import styles from "./scss/Nav.module.scss"
import { connect } from "react-redux"

class TicketNav extends Component {
	populateNav = () => {
		const { type, translation } = this.props

		const navList = [
			{
				text: "Buat Tiket",
				route: "/ticket/create",
				img: translation.img_create_ticket?.filename_disk,
				imgActive: translation.img_create_ticket?.filename_disk,
				type: "create",
			},
			{
				text: "List Tiket",
				route: "/ticket/list",
				img: translation.img_list_ticket?.filename_disk,
				imgActive: translation.img_list_ticket?.filename_disk,
				type: "list",
			},
			{
				text: "Ulasan Tiket",
				route: "/ticket/review",
				img: translation.img_list_ticket?.filename_disk,
				imgActive: translation.img_review_ticket?.filename_disk,
				type: "review",
			},
		]

		return navList.map((data, index) => (
			<Link
				key={index}
				to={data.route}
				className={`${styles.link} text-center ${type === data.type && styles.active}`}
				style={{
					background: `url(${process.env.REACT_APP_CDN_URL + data.imgActive}) center/auto 100% no-repeat`,
				}}
			/>
		))
	}

	render() {
		return (
			<div id="TicketNav" className={`${styles.Navcontainer}`}>
				<div className={`container ${styles.container}`}>
					<div className={`row ${styles.row}`}>{this.populateNav()}</div>
				</div>
			</div>
		)
	}
}
function mapStateToProps(state) {
	return { translation: state.main.translation }
}

export default connect(mapStateToProps)(TicketNav)
