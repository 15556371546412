const axios = require("axios").default

export const get_search_article = (query) => {
	return (dispatch) => {
		dispatch({
			type: "PUT_SEARCH_ARTICLE",
			data: undefined,
		})

		const options = {
			method: "SEARCH",
			url: `${process.env.REACT_APP_DIRECTUS}items/articles`,
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_DIRECTUS_KEY}`,
				"Content-Type": "application/json",
			},
			data: {
				query: {
					fields: "*.*",
					filter: {
						status: { _eq: "published" },
						translations: { _or: [{ title: { _contains: query } }, { content: { _contains: query } }] },
					},
				},
			},
		}

		axios
			.request(options)
			.then((resp) => {
				dispatch({
					type: "PUT_SEARCH_ARTICLE",
					data: resp.data.data,
				})
			})
			.catch((err) => {
				console.log(err)
			})
	}
}
