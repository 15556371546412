import React, { Component } from "react"
import Modal from "react-responsive-modal"
import StarRatings from "react-star-ratings"
import { connect } from "react-redux"

import styles from "./scss/Review.module.scss"

class ReviewModal extends Component {
	state = {
		rating: 0,
		content: "",
		feedback_suggestion: [],
	}

	changeRating = (newRating) => {
		const { feedback_list } = this.props
		this.setState({
			rating: newRating,
		})
		feedback_list(newRating)
	}

	handlePick = (data) => {
		let feed = this.state.feedback_suggestion
		let index = feed.indexOf(data)
		if (index > -1) {
			feed.splice(index, 1)
			this.setState({
				feedback_suggestion: feed,
			})
		} else {
			feed.push(data)
			this.setState({
				feedback_suggestion: feed,
			})
		}
	}

	handleChange = (e) => {
		let val = e.currentTarget.value
		val = val.replace(/\n/g, "<br>\n")

		this.setState({
			content: val,
		})
	}

	handleRate = () => {
		const { rate, ticket_number } = this.props
		let formdata = new FormData(),
			self = this
		formdata.append("lang", window.localStorage.getItem("lang"))

		for (const key in this.state) {
			if (key === "feedback_suggestion") {
				let feedback = this.state.feedback_suggestion
				let final = feedback.toString().replace(/,/g, " | ")
				formdata.append(key, final)
			} else {
				formdata.append(key, this.state[key])
			}
		}

		rate(ticket_number, formdata, () => {
			self.setState({
				rating: 0,
				content: "",
				feedback_suggestion: [],
			})
		})
	}

	renderText = (rate) => {
		const { translation } = this.props

		let text = ""
		switch (rate) {
			case 1:
				text = translation.txt_rate_response_1
				break
			case 5:
				text = translation.txt_rate_response_5
				break
			default:
				text = ""
				break
		}
		return text
	}

	renderCheckBox = () => {
		const { list_data } = this.props
		const { feedback_suggestion } = this.state
		let view = ""
		if (list_data && list_data.length > 0) {
			view = (
				<ol className={`${styles.listFeedback}`}>
					{list_data.map((data, x) => (
						<li
							key={x}
							className={feedback_suggestion.indexOf(data) > -1 ? `${styles.active}` : ``}
							onClick={() => this.handlePick(data)}
						>
							{data}
						</li>
					))}
				</ol>
			)
		}
		return view
	}

	render() {
		const { open, closeModal, ticket_number, translation } = this.props

		return (
			<Modal
				open={open}
				onClose={() => closeModal()}
				center
				classNames={{
					modal: styles.modal,
					closeButton: window.localStorage.lang === "ar" ? styles.closeBtn_ar : styles.closeBtn,
				}}
			>
				<div
					className={styles.title}
					dangerouslySetInnerHTML={{
						__html: translation.txt_close_ticket_content?.replace("{ticket_number}", ticket_number),
					}}
				/>
				<div className={`${styles.ratingCont} mt-3`}>
					<h6>{translation.txt_rating}</h6>
					<StarRatings
						rating={this.state.rating}
						starRatedColor="orange"
						starHoverColor="orange"
						changeRating={this.changeRating}
						numberOfStars={5}
						starDimension="1.5vw"
						starSpacing=".1vw"
						name="rating"
					/>
				</div>
				<div className={`${styles.textCont} mt-3`}>
					<h6>{translation.txt_comment}</h6>
					<textarea
						placeholder={translation.txt_comment_placeholder}
						className="form-control"
						id=""
						cols="30"
						rows="3"
						value={this.state.content}
						onChange={this.handleChange}
					></textarea>
					{(this.state.rating === 1 || this.state.rating === 5) && (
						<p>{this.renderText(this.state.rating)}</p>
					)}
					{this.renderCheckBox()}
				</div>
				<div className={`btn btn-primary mt-3 ${styles.btn}`} onClick={this.handleRate}>
					{translation.txt_submit_review}
				</div>
			</Modal>
		)
	}
}

function mapStateToProps(state) {
	return { translation: state.main.translation }
}

export default connect(mapStateToProps)(ReviewModal)
