import React, { Component } from "react"
import styles from "./scss/Footer.module.scss"
import { connect } from "react-redux"

class Footer extends Component {
	render() {
		const { translation } = this.props
		return (
			<div id="Footer" className={styles.footer}>
				{translation.txt_copyright}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return { translation: state.main.translation }
}

export default connect(mapStateToProps)(Footer)
